import { authHeader } from "../helpers";
import {defineConfig} from '../config';
import {utilsService} from './utils';
import FileSaver from 'file-saver';

export const insightService = {
  getApiPathInsight,
  parseInsight,
  getUploadInsightUrl,
  confirmAssociation,
  parseCorrections,
  confirmCorrections,
  editCorrections,
  checkCostByDate,
  checkCostByDates,
  confirmCosts,
  parseCosts,
  getInsDaily,
  getInsRanged,
  getInsTotal,
  getAllSitesRevenue,
  deletePlatformRevenue,
  getAssociatedFrom,
  deleteAssociation,
  getStatSiteByType,
  getTopPagesByType,
  getTopUnitsByType,
  getTopUnitsPrebid,
  getProcessStatus,
  getWebVitals,
  getPlatformsInsight,
  getPlatformDetail,
  getPrebid,
  editCosts,
  getTraffic,
  getPvPreview,
  editInsightPV,
  getCostsReport,
  importSites,
  downloadImportTemplate,
  getPrebidByBidder,
  getPrebidBySites,
  getPrebidInsRanged,
  getAllBiddersBySite,
  getPrebidChart,
  checkExportInsights,
  exportInsights,
};

const {handleResponse, handleResponseBlob} = utilsService;

const {apiUrl, apiVer} = defineConfig.INSIGHT_SERVICE

function getApiPathInsight(){
  return (apiUrl+apiVer)
}

function getUploadInsightUrl(){
    return apiUrl+apiVer+'/import/parse'
}

function parseInsight(files) {
  const url = apiUrl+apiVer+"/import/parse";

  const requestOptions = {
    method: "POST",
    headers:{"Authorization": authHeader().Authorization},
    body: files
  };

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      return res;
    });
}

function confirmAssociation(data) {
  const url = apiUrl+apiVer+"/import/confirm";

  const requestOptions = {
    method: "POST",
    headers: authHeader("application/json"),
    body: JSON.stringify(data)
  };

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      return res;
    });
}

function parseCorrections(files) {
  const url = apiUrl+apiVer+"/import/correction/parse";

  const requestOptions = {
    method: "POST",
    headers:{"Authorization": authHeader().Authorization},
    body: files
  };

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      return res;
    });
}


function editCorrections(data) {
  const url = apiUrl+apiVer+"/import/correction/edit";

  const requestOptions = {
    method: "POST",
    headers: authHeader("application/json"),
    body: JSON.stringify(data)
  };

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      return res;
    });
}

function confirmCorrections(data) {
  const url = apiUrl+apiVer+"/import/correction/confirm";

  const requestOptions = {
    method: "POST",
    headers: authHeader("application/json"),
    body: JSON.stringify(data)
  };

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      return res;
    });
}

function checkCostByDate(startDate, endDate) {
  const url = apiUrl+apiVer+'/import/cost/check?startDate='+ startDate + `${endDate ? ('&endDate=' + endDate) : ''}`;

  const requestOptions = {
    method: "GET",
    headers: authHeader("application/json"),
  };

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      return res;
    });
}

function checkCostByDates(dates) {
  const url = apiUrl+apiVer+'/import/cost/import/check';

  const requestOptions = {
    method: "POST",
    headers: authHeader("application/json"),
    body: JSON.stringify(dates)
  };

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      return res;
    });
}

function confirmCosts(data) {
  const url = apiUrl+apiVer+'/import/cost/confirm';

  const requestOptions = {
    method: "POST",
    headers: authHeader("application/json"),
    body: JSON.stringify(data)
  };

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      return res;
    });
}

function editCosts(data) {
  const url = apiUrl+apiVer+'/import/cost/edit';

  const requestOptions = {
    method: "POST",
    headers: authHeader("application/json"),
    body: JSON.stringify(data)
  };

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      return res;
    });
}

function parseCosts(files) {
  const url = apiUrl+apiVer+"/import/cost/parse";

  const requestOptions = {
    method: "POST",
    headers:{"Authorization": authHeader().Authorization},
    body: files
  };

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      return res;
    });
}

function getInsDaily(publisher, comparator, date, value, siteIds) {
  const url = apiUrl+apiVer+'/insight/daily';
  const requestOptions = {
    method: "POST",
    headers: authHeader('application/json'),
    body: JSON.stringify({
      "comparator":comparator,
      "date":date,
      "siteIds":siteIds,
      "value":value
    })
  };

  return fetch(
    url,
    requestOptions
    )
  .then(handleResponse)
  .then(res => {
    return res;
  });
}

function getInsRanged(publisher, comparatorRange, dateRange, siteIds, value) {
  const url = apiUrl+apiVer+'/insight/range';
  // const url = 'http://192.168.5.3:8071/v1/insight/range';

  const requestOptions = {
    method: "POST",
    headers: authHeader('application/json'),
    body: JSON.stringify({
      "companyId": publisher, 
      "comparator": comparatorRange,
      "date": dateRange,
      "siteIds": siteIds,
      "value": value
    })
  };

  return fetch(
    url,
    requestOptions
  )
  .then(handleResponse)
  .then(res => {
    return res;
  });
}

function getInsTotal(publisher, siteIds, value) {
  const url = apiUrl+apiVer+'/insight/total';

  const requestOptions = {
    method: "POST",
    headers: authHeader('application/json'),
    // headers: authHeader("application/json"),

    body: JSON.stringify({
      "siteIds":siteIds,
      "value":value
    })
  };

  return fetch(
    url,
    requestOptions
  )
  .then(handleResponse)
  .then(res => {
    return res;
  });
}

function getAllSitesRevenue(page, perPage, companyId , siteIds, sortType, sorter, startDate, endDate, filter) {
  const url = apiUrl+apiVer+"/insight/allRevenue/page/" + page + "/perPage/" + perPage + "/sort/" + sortType + "/sortBy/" + sorter;

  const requestOptions = {
    method: "POST",
    headers: authHeader('application/json'),
    body: JSON.stringify({
      startDate, 
      endDate, 
      companyId,
      siteIds, 
      filter
    })
  };

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      return res;
    });
}

function getAssociatedFrom(idSite) {
  const url = apiUrl+apiVer+"/import/associationBySite?idSite=" + idSite  ;

  const requestOptions = {
    method: "GET",
    headers: authHeader("application/json"),
  };

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      return res;
    });
}

function deleteAssociation(id) {
  const url = apiUrl+apiVer+"/import/deleteAssociation?id=" + id  ;

  const requestOptions = {
    method: "POST",
    headers: authHeader("application/json"),
  };

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      return res;
    });
}


function getStatSiteByType(publisher, siteId, startDate, endDate, granularity, type) {
  const url = apiUrl+apiVer+'/insight/range/'+siteId;

  const requestOptions = {
    method: "POST",
    headers: authHeader('application/json'),

    body: JSON.stringify({
      "date": {
        "startDate": startDate,
        "endDate": endDate
      },
      "granularity": granularity,
      "value": type
    })
  };

  return fetch(
    url,
    requestOptions
  )
  .then(handleResponse)
  .then(res => {
    return res;
  });
}

function getTopPagesByType(body) {
  const url = apiUrl+apiVer+"/insight/topPages";

  const requestOptions = {
    method: "POST",
    headers: authHeader("application/json"),
    body: JSON.stringify(body)
  };

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      return res;
    });
}

function getTopUnitsByType(body) {
  const url = apiUrl+apiVer+"/insight/topUnits";

  const requestOptions = {
    method: "POST",
    headers: authHeader("application/json"),
    body: JSON.stringify(body)
  };

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      return res;
    });
}

function getTopUnitsPrebid(targetBidders, selectedDate, siteIds, companyId) {
  const url = apiUrl+apiVer+"/insight/prebid/units";

  const body = {
      bidder: targetBidders,
      date: selectedDate,
      siteIds: siteIds,
      companyId: companyId
  }
  const requestOptions = {
    method: "POST",
    headers: authHeader("application/json"),
    body: JSON.stringify(body)
  };

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      return res;
    });
}

function getProcessStatus(parseId) {
  const url = apiUrl+apiVer+`/import/status/${parseId}`;
  const requestOptions = {
    method: "GET",
    headers: authHeader("application/json"),
  };

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(result => {
      return result;

    });
}

function getWebVitals(siteId, startDate, endDate) {
  const url = apiUrl+apiVer+`/pageSpeed/getWebVitalsBySiteId?siteId=${siteId}&startDate=${startDate}&endDate=${endDate}`;
  const requestOptions = {
    method: "GET",
    headers: authHeader("application/json"),
  };

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(result => {
      return result;
    });
}

function getPlatformsInsight(body, top, limit) {
  const url = apiUrl+apiVer+"/insight/platform/range?top=" + top + "&limit=" + limit;

  const requestOptions = {
    method: "POST",
    headers: authHeader("application/json"),
    body: JSON.stringify(body)
  };

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      return res;
    });
}

function getPlatformDetail(body) {
  const url = apiUrl+apiVer+"/insight/platform/site";

  const requestOptions = {
    method: "POST",
    headers: authHeader("application/json"),
    body: JSON.stringify(body)
  };

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      return res;
    });
}

function getPrebid(body) {
  const url = apiUrl+apiVer+"/insight/prebid";

  const requestOptions = {
    method: "POST",
    headers: authHeader("application/json"),
    body: JSON.stringify(body)
  };

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      return res;
    });
}

function getTraffic(body) {
  const url = apiUrl+apiVer+"/insight/client";

  const requestOptions = {
    method: "POST",
    headers: authHeader("application/json"),
    body: JSON.stringify(body)
  };

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      return res;
    });
}

function getPvPreview(body) {
  const url = apiUrl+apiVer+"/insight/preview";

  const requestOptions = {
    method: "POST",
    headers: authHeader("application/json"),
    body: JSON.stringify(body)
  };

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      return res;
    });
}

function editInsightPV(body) {
  const url = apiUrl+apiVer+"/insight/edit";

  const requestOptions = {
    method: "POST",
    headers: authHeader("application/json"),
    body: JSON.stringify(body)
  };

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      return res;
    });
}

function getCostsReport(body) {
  const url = apiUrl+apiVer+"/insight/report/platformNetCost";

  const requestOptions = {
    method: "POST",
    headers: authHeader("application/json"),
    body: JSON.stringify(body)
  };

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      return res;
    });
}

function importSites(file) {
  const url = apiUrl+apiVer+"/import/importSites";

  const requestOptions = {
    method: "POST",
    headers:{"Authorization": authHeader().Authorization},
    body: file
  };

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      return res;
    });
}

function downloadImportTemplate() {
  const url = apiUrl+apiVer+"/import/importSitesTemplate?mock=true";

  const requestOptions = {
    method: "GET",
    headers:{"Authorization": authHeader().Authorization},
  };

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponseBlob)
    .then(res => {
      return FileSaver.saveAs(res, 'sites_import_template.xlsx');
    });
}

function getPrebidByBidder(body) {
  const url = apiUrl+apiVer+"/insight/prebid/byBidder";

  const requestOptions = {
    method: "POST",
    headers: authHeader("application/json"),
    body: JSON.stringify(body)
  };

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      return res;
    });
}

function getPrebidBySites(body) {
  const url = apiUrl+apiVer+"/insight/prebid/bySites";

  const requestOptions = {
    method: "POST",
    headers: authHeader("application/json"),
    body: JSON.stringify(body)
  };

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      return res;
    });
}

function getPrebidInsRanged(publisher, comparatorRange, dateRange, siteIds, bidders, type , value, granularity='DAILY') {
  const url = apiUrl+apiVer+'/insight/prebid/range';

  const requestOptions = {
    method: "POST",
    headers: authHeader('application/json'),
    body: JSON.stringify({
      "companyId": publisher, 
      "comparator": comparatorRange,
      "bidder": bidders,
      "type": type,
      "granularity": granularity,
      "date": dateRange,
      "siteIds": siteIds,
      "value": value
    })
  };

  return fetch(
    url,
    requestOptions
  )
  .then(handleResponse)
  .then(res => {
    return res;
  });
}

function getAllBiddersBySite(publisher, siteIds, ) {
  const url = apiUrl+apiVer+'/insight/prebid/distinct';

  const requestOptions = {
    method: "POST",
    headers: authHeader('application/json'),
    body: JSON.stringify({
      "companyId": publisher, 
      "siteIds": siteIds,
    })
  };

  return fetch(
    url,
    requestOptions
  )
  .then(handleResponse)
  .then(res => {
    return res;
  });
}

function getPrebidChart(value, type, targetBidders, selectedDate, targetSites, companyId ) {
  const url = apiUrl+apiVer+'/insight/prebid/chart';

  const requestOptions = {
    method: "POST",
    headers: authHeader('application/json'),
    body: JSON.stringify({
      "value": value, 
      "type": type,
      "bidder": targetBidders,
      "date": selectedDate,
      "siteIds": targetSites,
      "companyId": companyId
    })
  };

  return fetch(
    url,
    requestOptions
  )
  .then(handleResponse)
  .then(res => {
    return res;
  });
}

function deletePlatformRevenue(platformIds, selectedDate, preview) {
  const url = apiUrl+apiVer+'/insight/delete/byPlatform?preview=' + preview;

  const requestOptions = {
    method: "DELETE",
    headers: authHeader('application/json'),
    body: JSON.stringify({
      "platformIds": platformIds, 
      "date": selectedDate
    })
  };

  return fetch(
    url,
    requestOptions
  )
  .then(handleResponse)
  .then(res => {
    return res;
  });
}

function checkExportInsights(companyId, siteIds, dates) {
  const url = apiUrl+apiVer+'/insight/checkExport';

  const requestOptions = {
    method: "POST",
    headers: authHeader('application/json'),
    body: JSON.stringify({
    "companyId": companyId,
      "date": {
        "endDate": dates.endDate,
        "startDate": dates.startDate
      },
      "siteIds": siteIds
    })
  };

  return fetch(
    url,
    requestOptions
  )
  .then(handleResponse)
  .then(res => {
    return res;
  });
}

function exportInsights(companyId, siteIds, dates, metric, granularity, selectedView) {
  let url = apiUrl+apiVer+'/insight/export/metric';

  if(selectedView === "SITE") {
    url += "BySite";
  }

  const requestOptions = {
    method: "POST",
    headers: authHeader('application/json'),
    body: JSON.stringify({
      "companyId": companyId,
      "date": {
        "endDate": dates.endDate,
        "startDate": dates.startDate
      },
      "siteIds": siteIds ? siteIds : [],
      "value": metric,
      "granularity": granularity
    })
  };
  
  if(selectedView === "COMPANY") {
    return fetch(
      url,
      requestOptions
    )
    .then(handleResponseBlob)
    .then(res => {
      let timestamp = Date.now()
      return FileSaver.saveAs(res, `report_${metric}_${timestamp}.xlsx`);
    });
  } else {
    return fetch(
      url,
      requestOptions
    )
    .then(handleResponse)
    .then(res => {
      return res;
    });
  }
}